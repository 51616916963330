import styled from "styled-components";
import * as Colors from "../../utils/colors";

export const Wrapper = styled("div")`
  padding: 80px 40px;
  overflow-x: auto;

  a {
    display: inline-block;
    background: ${Colors.PRIMARY};
    padding: 10px 20px;
    color: ${Colors.WHITE};

    &:hover {
      color: ${Colors.WHITE};
      background: ${Colors.BLACK};
    }
  }

  .ant-table {
    width: 100%;
    margin: 0 auto 50px;

    table {
      @media (min-width: 1200px) {
        table-layout: fixed;
      }
    }

    @media screen and (min-width: 992px) {
      width: 60%;
    }

    tr {
      th {
        border-bottom: none;
        background: none;
        vertical-align: bottom;

        img {
          width: 120px;
        }
      }
      td {
        border: solid 1px ${Colors.GRAY_15};
      }
    }

    .nona {
      display: inline;
      background: none;
      padding: inherit;
      color: #ff0000;

      &:hover {
        color: ${Colors.BLACK};
        background: inherit;
        font-weight: bold;
      }
    }

    .red {
      color: #ff0000;
    }
  }

  .p-fixed {
    position: fixed;
    width: 60%;
    top: 70px;
    z-index: 4;

    @media (max-width: 767px) {
      top: 53px;
      width: 100%;
      overflow-x: scroll;
    }
  }

  #d-fixed {
    background: #fff;
    scroll-behavior: smooth;
    //   .ant-table-tbody {
    //     visibility: collapse;
    //     -webkit-visibility: collapse;
    //     -moz-visibility: collapse;
    //   }
    // }

    // #product-table {
    //   .ant-table-thead {
    //     visibility: collapse;
    //     -webkit-visibility: collapse;
    //     -moz-visibility: collapse;
    //   }
  }

  @media (max-width: 1024px) {
    #d-fixed {
      display: none !important;
    }
    #product-table {
      .ant-table-thead {
        display: revert !important;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 80px 20px;
  }
`;

export const ContentWrapper = styled("div")`
  margin: 40px 0;
  text-align: center;
`;

export const SubTitle = styled("p")`
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 15px;
`;

export const Title = styled("h1")`
  color: ${Colors.TEXT_DEFAULT};

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const Text = styled("p")`
  ${({ highlight }) =>
    highlight &&
    `text-align: center; color: ${Colors.PRIMARY}; font-weight: bold;`}
`;

export const PlanChatWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;

  a {
    margin-left: 40px;
    display: inline-block;
    background: ${Colors.PRIMARY};
    padding: 10px 20px;
    color: ${Colors.WHITE};

    &:hover {
      color: ${Colors.WHITE};
    }

    @media screen and (max-width: 992px) {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const PlanChatTitle = styled("h1")`
  color: ${Colors.TEXT_DEFAULT};
  margin: 0;
  text-align: right;

  @media screen and (max-width: 575px) {
    font-size: 18px;
    line-height: 18px;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const List = styled("ul")`
  li {
    list-style-type: circle;
    font-size: 14px;
    margin-left: 10px;
  }
`;
