import React from "react";
import { Button, notification } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import {
  Wrapper,
  FloatingIcon,
  Content,
  CookieWrapper,
  CookieText,
  CookieHeader,
  CookieContent,
  CookieActions,
  CookieContainer,
  ProductPurchase,
} from "./styles";
import { Footer } from "../../../components/Navigation";
import FreeGift from "../../../components/FreeGift";
import Header from "../../../components/Navigation/Header";
import Logo from "../../../assets/images/logo.png";
import HeirsLogo from "../../../assets/images/heirs-logo.png";
import { useEffect } from "react";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import axios from "axios";

const openNotification = () => {
  axios({
    method: "GET",
    url: `https://report.services.heirslifeassurance.com/api/v1/purchase/recent/hla/customer`,
    data: {},
  }).then((response) => {
    if (response.status === 200) {
      const loopFunc = {
        max: response.data.length,
        current: 0,
        timeout: null,
        loop: function (params) {
          if (response.data.length > 0) {
            if (
              response.data[this.current].name &&
              response.data[this.current].product
            ) {
              const key = `open${this.current}`;
              notification.open({
                message: "",
                description: (
                  <>
                    <ProductPurchase>
                      {response.data[this.current]?.name
                        ? response.data[this.current].name.split(" ")[0]
                        : ""}
                    </ProductPurchase>
                    {response.data[this.current]?.location?.city ? ` in ` : ""}
                    <ProductPurchase>
                      {response.data[this.current]?.location?.city ?? ""}
                    </ProductPurchase>{" "}
                    just bought{" "}
                    <ProductPurchase>
                      {response.data[this.current]?.product
                        ? response.data[this.current].product.split("-")[0]
                        : ""}
                    </ProductPurchase>
                  </>
                ),
                placement: "center",
                key,
                maxCount: 1,
                duration: 15,
              });
              setTimeout(() => {
                notification.close(key);
              }, 20000);
            }
            this.current = (this.current + 1) % this.max;
            this.timeout = setTimeout(function () {
              loopFunc.loop();
            }, 120000);
          }
        },
        stop: function () {
          clearTimeout(this.timeout);
        },
      };
      loopFunc.loop();
      // setTimeout(function () {
      //   loopFunc.stop();
      // }, 500);
    }
  });
};
openNotification();
export const withWrapper = (Component) => (props) => {
  const accepted = window.localStorage.getItem("lifeCookieAccepted");
  const [acceptedValue, setAcceptedValue] = useState(false);
  const [visible, setVisible] = useState(false);
  const [timer, setStartTimer] = useState(false);
  const handleOnIdle = (event) => {
    if (timer) {
      pause();
      setVisible(true);
      console.log(visible, "got hheree");
    }
  };

  const { pause } = useIdleTimer({
    timeout: 120000,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  const handleCookieAccept = () => {
    localStorage.setItem("lifeCookieAccepted", "true");
    document.cookie = "lifeCookieAccepted=true";
    setAcceptedValue(true);
  };
  const handleCookieReject = () => {
    localStorage.setItem("lifeCookieAccepted", "false");
    document.cookie = "lifeCookieAccepted=false";
    setAcceptedValue(true);
  };

  useEffect(() => {
    if (accepted && !acceptedValue) {
      setAcceptedValue(true);
    }
    const handleExit = (event) => {
      if (
        localStorage.getItem("free_gift_filled") ||
        localStorage.getItem("attempted_purchase")
      )
        return;
      event.preventDefault();
      setVisible(true);
      return (event.returnValue = "Are you sure you want to close?");
    };
    const handleRemoveExit = (event) => {
      if (
        localStorage.getItem("free_gift_filled") ||
        localStorage.getItem("attempted_purchase")
      )
        return;
      event.preventDefault();
      setVisible(false);
      return (event.returnValue = "Are you sure you want to close?");
    };
    console.log(
      localStorage.getItem("attempted_purchase"),
      localStorage.getItem("last_accessed_product")
    );
    if (
      !localStorage.getItem("attempted_purchase") &&
      !localStorage.getItem("free_gift_filled") &&
      localStorage.getItem("last_accessed_product")
    ) {
      // setStartTimer(true);
      // window.addEventListener("beforeunload", handleExit);
    } else {
      // setStartTimer(false);
      // window.removeEventListener("beforeunload", handleRemoveExit);
    }
    console.log(window.location.pathname);
  }, [accepted, acceptedValue, visible]);

  return (
    <Wrapper>
      {!acceptedValue ? (
        <CookieWrapper>
          {/* <Alert
            message="We use cookies to ensure that we offer you the best experience while browsing our website."
            type="info"
            action={
              <Space direction="horizontal">
                <Button
                  size="small"
                  type="primary"
                  onClick={handleCookieAccept}
                >
                  Accept
                </Button>
                <Button
                  size="small"
                  danger
                  type="ghost"
                  onClick={handleCookieReject}
                >
                  Decline
                </Button>
              </Space>
            }
            onClos
          /> */}
          <CookieContainer>
            <CookieHeader>This website uses cookies.</CookieHeader>
            <CookieText>
              <CookieContent>
                We use cookies to ensure that we offer you the best experience
                while browsing our website. Check our{" "}
                <a href="/privacy">Privacy and Policy</a> for more information.
              </CookieContent>
              <CookieActions>
                <Button
                  size="small"
                  danger
                  type="ghost"
                  onClick={handleCookieReject}
                >
                  Decline
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={handleCookieAccept}
                >
                  Accept
                </Button>
              </CookieActions>
            </CookieText>
          </CookieContainer>
        </CookieWrapper>
      ) : null}

      <Header logo={Logo} />

      <Content>
        <Component {...props} />
      </Content>

      <Footer />
      <FreeGift visible={visible} />
      {/* <FloatingIcon>
        <WhatsAppOutlined
          onClick={() => {
            window
              .open("https://wa.me/2349122222200?text=Hi", "_blank")
              .focus();
          }}
          style={{ color: "white", cursor: "pointer", marginTop: "16px" }}
        />
      </FloatingIcon> */}
    </Wrapper>
  );
};

export const withoutFooter = (Component) => (props) => {
  const accepted = window.localStorage.getItem("lifeCookieAccepted");
  const [acceptedValue, setAcceptedValue] = useState(false);

  const handleCookieAccept = () => {
    localStorage.setItem("lifeCookieAccepted", "true");
    document.cookie = "lifeCookieAccepted=true";
    setAcceptedValue(true);
  };
  const handleCookieReject = () => {
    localStorage.setItem("lifeCookieAccepted", "false");
    document.cookie = "lifeCookieAccepted=false";
    setAcceptedValue(true);
  };

  useEffect(() => {
    if (accepted && !acceptedValue) {
      setAcceptedValue(true);
    }
  }, [accepted, acceptedValue]);

  return (
    <Wrapper>
      <Header onLandingPage={true} logo={HeirsLogo} />

      {!acceptedValue ? (
        <CookieWrapper>
          {/* <Alert
            message="Our website uses cookies to improve your experience. By continuing to use this website, you consent to the use of cookies in accordance with our policy."
            type="info"
            showIcon
            action={
              <Space direction="vertical">
                <Button
                  size="small"
                  type="primary"
                  onClick={handleCookieAccept}
                >
                  Accept
                </Button>
                <Button
                  size="small"
                  danger
                  type="ghost"
                  onClick={handleCookieReject}
                >
                  Decline
                </Button>
              </Space>
            }
            onClos
          /> */}

          <CookieContainer>
            <CookieHeader>This website uses cookies.</CookieHeader>
            <CookieText>
              <CookieContent>
                We use cookies to ensure that we offer you the best experience
                while browsing our website. Check our{" "}
                <a href="/privacy">Privacy and Policy</a> for more information.
              </CookieContent>
              <CookieActions>
                <Button
                  size="small"
                  danger
                  type="ghost"
                  onClick={handleCookieReject}
                >
                  Decline
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={handleCookieAccept}
                >
                  Accept
                </Button>
              </CookieActions>
            </CookieText>
          </CookieContainer>
        </CookieWrapper>
      ) : null}
      <Content>
        <Component {...props} />
      </Content>
    </Wrapper>
  );
};

export default withWrapper;
