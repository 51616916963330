import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { List } from "../CreditPlanPicker/styles";
import { Text, Title, Wrapper } from "../PlanPicker/styles";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
import myHeirs from "../../assets/images/myheirs-image.png";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "./../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="heirs">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <img src={myHeirs} alt="my children plan" />
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>MyHeirs</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Children Education Buy")}
                    href={`${HeirsDashboardUrl}/children-type?policy-type=MyHeirs Plan`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Children Education quote")}
                    href={`${HeirsDashboardUrl}/children-type?policy-type=MyHeirs Plan`}
                    style={{ margin: "2px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>MyHeirs Plus</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Children Education Plus Buy")}
                    href={`${HeirsDashboardUrl}/children-type?policy-type=MyHeirs Plus Plan`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Children Education Plus quote")}
                    href={`${HeirsDashboardUrl}/children-type?policy-type=MyHeirs Plus Plan`}
                    style={{ margin: "2px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Works as:</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>A savings + Life insurance plan</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>An Endowment plan (inheritance)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>For who</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Parents, grandparents and guardians</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Parents, grandparents and guardians</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Age limit of parent</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>18 - 60 yrs</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>18- 65 years</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Savings Contribution</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>From N5,000 monthly</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not applicable</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Contribution Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Monthly, quarterly, half-yearly, yearly</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not applicable</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Benefit</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      If there's loss of life, your child gets: Total target
                      amount plus total savings plus interest.
                    </li>
                    <li>
                      If you survive the plan, you get: Total savings plus
                      interest.
                    </li>
                    <li>Heirs Life Rewards Membership*</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      If there's loss of life, your child gets: Total target
                      amount plus bonus.
                    </li>
                    <li>
                      If you survive the plan, you get: Total target amount plus
                      bonus.
                    </li>
                    <li>Heirs Life Rewards Membership*</li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Interest</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Interest is current bank savings interest + 0.25% per annum.
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    2% per annum of the target amount chosen (secured upon
                    complete payment of yearly premium)
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="3">
                  <Text highlight>Life Insurance</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support to your beneficiary if there's loss of
                    life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N5million maximum</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Target amount to be chosen by you</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost of Life Insurance</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>1% of the target life insurance amount</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Minimum of N5,000 monthly</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Frequency of Payment</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Once annually </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Monthly, quarterly, half-yearly, yearly</Text>
                </td>
              </tr>

              <tr>
                <td className="ant-table-cell" colSpan="3">
                  <Text highlight>Other Details</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Minimum Duration</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>3 years</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>5 years</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Forfeit Terms</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      Discontinue plan before 6months: Receive only savings
                      without interest
                    </li>
                    <li>
                      Discontinue plan after 6months: Receive complete savings
                      plus interest
                    </li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      If you terminate the plan within the first 2 years, you
                      agree to forfeit all benefits of this plan including sum
                      assured.
                    </li>
                    <li>
                      After the first 2 years, you can only get a percentage of
                      your premium.
                    </li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Medical Examination of parent</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not required</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Based on the target amount chosen</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Account statement</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Request online, USSD and on mobile</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Request online, USSD and on mobile</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Documents Required</Text>
                </td>
                <td className="ant-table-cell" colspan="2">
                  <List>
                    <li>Duly Filled Proposal Form.</li>
                    <li>Evidence of Payment.</li>
                    <li>
                      Valid means of ID - Driver license, Voter's card, NIMC,
                      National ID, International Passport.
                    </li>
                    <li>
                      Proof of residence - Nepa or Lawma bill, Front page of the
                      customer's address (same address as indicated on the form
                      or portal), Local government levy.
                    </li>
                    <li>
                      Duly Filled CTR, Financial Questionnaire, Address
                      Verification Form, Bank Statement not more than 6-months
                      (if premium is more than N5 million).
                    </li>
                  </List>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
