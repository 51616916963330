import React from "react";
import Icon from "@ant-design/icons";
import { IconWrapper } from "../style";
import { Menu, Grid } from "antd";
import { Link } from "react-router-dom";
import {
  HeirsDashboardUrl,
  // HeirsInsuranceUrl,
} from "../../../../utils/constant";
import SubMenu from "antd/lib/menu/SubMenu";
// import { Link } from "react-router-dom";
import { ReactComponent as ComplaintIcon } from "../../../../assets/images/complaint.svg";
const { useBreakpoint } = Grid;

const RightMenu = ({ onLandingPage }) => {
  const { lg } = useBreakpoint();
  return (
    <Menu mode={lg ? "horizontal" : "inline"}>
      {onLandingPage ? (
        <>
          <Menu.Item key="app">
            <a href={`${HeirsDashboardUrl}/signin`}>Self Service</a>
          </Menu.Item>
          {/* <Menu.Item key="pay">
            <a href={`/claims`}>Fast Claims</a>
          </Menu.Item> */}
          <Menu.SubMenu title="Fast Claims" id="right">
            {/* <Menu.Item key="hil claim">
              <a href={`${HeirsInsuranceUrl}/claims`}>Heirs Insurance</a>
            </Menu.Item> */}
            <Menu.Item key="hla claim">
              <a href="/claims">Heirs Life</a>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="complaints">
            <a href="/complaint">Complaint</a>
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="app">
            <a href={`${HeirsDashboardUrl}/signin`}>Self Service</a>
          </Menu.Item>
          <Menu.Item key="hla claim">
            <a href="/claims">Fast Claims</a>
          </Menu.Item>
          <Menu.Item key="complaints">
            <a href="/complaint">Complaint</a>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

export default RightMenu;
