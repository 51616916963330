import styled from "styled-components";
import { Link as ReactLink } from "react-router-dom";
import { Layout } from "antd";
import * as Colors from "../../../utils/colors";
import footerImage from "../../../assets/images/footer-pattern.svg";
import { containerWrapper } from "../../../utils/stylescheet";

export const Container = styled(Layout.Footer)`
  background-color: ${Colors.WHITE};
  background-image: url(${footerImage});
  background-repeat: repeat-x;
  background-size: contain;
  border-top: 1px solid rgba(223, 227, 255, 0.5);
  padding: 70px 0 0;
`;

export const Wrapper = styled("div")`
  ${containerWrapper};

  .ant-row {
    margin-left: 0 !important;
    width: 100%;
    & .ant-col {
      padding-left: 0 !important;

      &:last-child {
        padding-right: 0 !important;
      }
    }
  }

  li {
    line-height: 35px;

    a {
      color: ${Colors.TEXT_DEFAULT};

      &:hover {
        color: #ff5254;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    .ant-row {
      row-gap: 50px !important;
    }
  }

  @media screen and (max-width: 480px) {
    padding-top: 0;
  }

  @media screen and (min-width: 576px) {
    padding-top: 0;
  }

  @media screen and (min-width: 768px) {
    padding-top: 5px;
  }

  @media screen and (min-width: 992px) {
    padding-top: 15px;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 30px;
  }

  @media screen and (min-width: 1600px) {
    padding-top: 50px;
  }
`;

export const Title = styled("h2")`
  color: ${Colors.PRIMARY};
  margin-bottom: 10px;
`;

export const Text = styled("p")`
  margin-bottom: 15px;
`;

export const ImageWrapper = styled("div")`
  img {
    width: 100px;
    height: auto;
    margin: 0 10px;
  }
`;

export const IconWrapper = styled("div")`
  text-align: center;

  .anticon {
    display: block;
  }

  img {
    width: 100%;
  }
  svg {
    width: 100%;
    height: auto;
  }
`;

export const Link = styled(ReactLink)`
  color: ${Colors.TEXT_DEFAULT};
`;

export const SubTitle = styled("p")`
  text-align: center;
`;

export const DownloadItemContent = styled("div")`
  display: inline-block;
  vertical-align: middle;

  ${SubTitle} {
    text-align: left;
  }

  ${Title} {
    margin-bottom: 0;
  }

  svg {
    width: 30px;
    height: auto;
    fill: ${Colors.TEXT_DEFAULT};
    margin-right: 10px;
  }
`;

export const DownloadItemWrapper = styled("div")`
  padding: 15px 25px 10px;
  border-radius: 45px;
  border: solid 1px ${Colors.GRAY_65};
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 20px;

  ${Title} {
    text-transform: capitalize !important;
    font-size: 13px;
  }

  ${SubTitle} {
    font-size: 10px;
  }
`;

export const DownloadWrapper = styled("div")`
  margin-top: 30px;
  ${Title} {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 15px;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    color: ${Colors.TEXT_DEFAULT};
  }

  @media screen and (min-width: 992px) {
    bottom: 50px;
    left: 0;
    right: 0;
  }

  img {
    width: 120px;
    height: auto;
    margin-right: 15px;
  }
`;

export const SocialMediaWrapper = styled("div")`
  display: flex;

  img {
    width: 32px;
    margin-right: 15px;
  }
`;
