import React, { useState } from "react";
import { Row, Col } from "antd";
import {
  Wrapper,
  ItemWrapper,
  Title,
  ListWrapper,
  ListItemWrapper,
  Text,
} from "./styles";
import Lottie from "lottie-react-web";
import animationData from "../../assets/animations/about-us.json";
const defaultOptions = {
  loop: true,
  animationData,
};
const Index = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Wrapper>
      <Row gutter={[16, 0]}>
        <Col lg={16}>
          <ListWrapper>
            <ListItemWrapper
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              What We Do
            </ListItemWrapper>
            <ListItemWrapper
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              Our Purpose
            </ListItemWrapper>
            <ListItemWrapper
              active={activeTab === 2}
              onClick={() => setActiveTab(2)}
            >
              Our Approach
            </ListItemWrapper>
          </ListWrapper>

          <ItemWrapper active={activeTab === 0}>
            <Title>What We Do</Title>
            <Text>
              Heirs Life Assurance Limited (HLA) is a specialist life insurance
              company. We challenge traditional insurance with 21st-century
              digital tools and technology, to provide simple, quick, reliable,
              and accessible financial security plans to individuals and
              businesses.
            </Text>

            <Text>
              With a paid-up share capital of N8billion, Heirs Life’s product
              offerings comprise insurance-backed savings plans and life
              insurance security for individuals, families, children’s
              education, debtors, creditors, entrepreneurs, and employees.
            </Text>
            <Text>
              The company is backed by top-notch Reinsurers to provide
              second-layer security for its clients’ insurance portfolios. Heirs
              Life Assurance is a subsidiary of Heirs Holdings, a pan-African
              investment group with presence across 3 continents and 20 African
              countries.
            </Text>
          </ItemWrapper>

          <ItemWrapper active={activeTab === 1}>
            <Title>Committed to Improving Lives and transforming Nigeria</Title>
            <Text>
              We are Africapitalists at heart and in deed. We believe that the
              private sector must play the lead role in the economic
              transformation of Nigeria. As insurers, we live this philosophy by
              driving financial inclusion across all levels and providing
              financial security, even before it is needed.
            </Text>
          </ItemWrapper>

          <ItemWrapper active={activeTab === 2}>
            <Title>Insurance, Made Simple</Title>
            <Text>
              Our mission is to make insurance accessible to everyone. We
              believe that by making insurance easy to understand and creating
              relevant plans to match a variety of needs, we empower people to
              dream and pursue their ambition, without fear.
            </Text>
            <Title>Smart Protection, Made Just for You </Title>
            <Text>
              Nobody can predict the future. We all wish we could, so we can
              live the way we really want to. With Heirs Life, we take on the
              risks so you can get on with your life. We go a step further by
              analysing trends and offering timely life-enhancing information,
              to put you in control.
            </Text>
          </ItemWrapper>
        </Col>
        <Col lg={8}>
          <Lottie width="100%" options={defaultOptions} />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Index;
