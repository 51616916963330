import React from "react";
import Marquee from "react-fast-marquee";
import TestimonialCard from "./TestimonialCard";
import { TestimonialCardWrapper } from "../style";
import MayowaImage from "../../../assets/images/mayowa.jpeg";
import OlamideImage from "../../../assets/images/olamide.jpeg";
import YetundeImage from "../../../assets/images/yetunde.jpeg";
import WoleImage from "../../../assets/images/wole.jpeg";
import NimiImage from "../../../assets/images/nimi.jpeg";

const TestimonialCards = () => {
  const messages = [
    {
      firstName: "Mayowa",
      lastName: "",
      message:
        "The Heirs Save plan has helped me build a saving culture. My dad would be proud of me😁",
      avatarUrl: MayowaImage,
    },
    {
      firstName: "Yetunde",
      lastName: "",
      message:
        "I used to think that Life insurance was so boring until Heirs Life came into the game. You guys are making insurance fun and easy to understand, kudos!",
      avatarUrl: YetundeImage,
    },
    {
      firstName: "Wole",
      lastName: "",
      message:
        "The customer service lady made my selection and subscription process super-fast",
      avatarUrl: WoleImage,
    },
    {
      firstName: "Nimi",
      lastName: "",
      message:
        "Fantastic customer service, exciting plans. This is the new face of insurance of Nigeria.",
      avatarUrl: NimiImage,
    },
    {
      firstName: "Olamide",
      lastName: "",
      message:
        "The speed did it for me. Accuracy with the plan they offered me too. It's as though they could read my mind.",
      avatarUrl: OlamideImage,
    },
  ];
  return (
    <TestimonialCardWrapper>
      <Marquee speed={40} pauseOnHover={true}>
        {messages.map(
          ({ firstName, lastName, username, message, avatarUrl }, key) => (
            <TestimonialCard
              firstName={firstName}
              lastName={lastName}
              message={message}
              avatarUrl={avatarUrl}
              key={key}
            />
          )
        )}
      </Marquee>
    </TestimonialCardWrapper>
  );
};

export default TestimonialCards;
