import { withWrapper, withoutFooter } from "../HOC/AppWrapper";
import * as Screen from "../../screens";

const routes = [
  {
    path: "/",
    component: withWrapper(Screen.Home),
    // component: withoutFooter(Screen.Start),
  },
  {
    path: "/home",
    component: withWrapper(Screen.Home),
  },
  {
    path: "/smart-school",
    component: withWrapper(Screen.Product),
  },
  {
    path: "/heirs-save",
    component: withWrapper(Screen.Savings),
  },
  {
    path: "/salary",
    component: withWrapper(Screen.Salary),
  },
  {
    path: "/whole-life",
    component: withWrapper(Screen.WholeLife),
  },
  {
    path: "/couples",
    component: withWrapper(Screen.Couples),
  },
  {
    path: "/credit-life",
    component: withWrapper(Screen.Credit),
  },
  {
    path: "/endowment",
    component: withWrapper(Screen.Endowment),
  },
  {
    path: "/triple-pay",
    component: withWrapper(Screen.TriplePayInvestment),
  },
  {
    path: "/term-assure",
    component: withWrapper(Screen.TermAssure),
  },
  {
    path: "/entrepreneur",
    component: withWrapper(Screen.Entrepreneur),
  },
  {
    path: "/claims",
    component: withWrapper(Screen.Claims),
  },
  {
    path: "/my-heirs",
    component: withWrapper(Screen.MyHeirs),
  },
  {
    path: "/group-life",
    component: withWrapper(Screen.GroupLife),
  },
  {
    path: "/mortgage",
    component: withWrapper(Screen.Mortgage),
  },
  {
    path: "/keyman-assurance",
    component: withWrapper(Screen.Keyman),
  },
  {
    path: "/annuity",
    component: withWrapper(Screen.Annuity),
  },
  {
    path: "/who-we-are",
    component: withWrapper(Screen.About),
  },
  {
    path: "/career",
    component: withWrapper(Screen.Career),
  },
  {
    path: "/digital-channels",
    component: withWrapper(Screen.DigitalChannels),
  },
  {
    path: "/answers",
    component: withWrapper(Screen.GetAnswer),
  },
  {
    path: "/wp-media",
    component: withWrapper(Screen.Media),
  },
  {
    path: "/wp-media/:id",
    component: withWrapper(Screen.MediaPost),
  },
  {
    path: "/blog",
    component: withWrapper(Screen.WPMedia),
  },
  {
    path: "/news",
    component: withWrapper(Screen.News),
  },
  {
    path: "/essay",
    component: withWrapper(Screen.SchoolEssay),
  },
  {
    path: "/blog/:id",
    component: withWrapper(Screen.WPMediaPost),
  },
  {
    path: "/news/:id",
    component: withWrapper(Screen.NewsPost),
  },
  {
    path: "/financial-statements",
    component: withWrapper(Screen.FinancialStatements),
  },
  {
    path: "/corporate-governance",
    component: withWrapper(Screen.CorporateGovernance),
  },
  {
    path: "/product-flyers",
    component: withWrapper(Screen.ProductFlyers),
  },
  {
    path: "/privacy",
    component: withWrapper(Screen.Privacy),
  },
  {
    path: "/communication",
    component: withWrapper(Screen.Communication),
  },
  {
    path: "/terms",
    component: withWrapper(Screen.Terms),
  },
  {
    path: "/contact",
    component: withWrapper(Screen.Contact),
  },
  {
    path: "/complaint",
    component: withWrapper(Screen.Complaint),
  },
  {
    path: "/myheirs-campaign",
    component: withWrapper(Screen.MyHeirsCampaign),
  },
  {
    path: "/back2school-campaign",
    component: withWrapper(Screen.Back2SchoolCampaign),
  },
  {
    path: "/salaryplus-campaign",
    component: withWrapper(Screen.SalaryPlusCampaign),
  },
  {
    path: "/triplepay-campaign",
    component: withWrapper(Screen.TriplePayCampaign),
  },
  {
    path: "/surgery-campaign",
    component: withWrapper(Screen.SurgeryCampaign),
  },
  {
    path: "/heirssave-campaign",
    component: withWrapper(Screen.HeirsSaveCampaign),
  },
  {
    path: "/partnership-portal",
    component: withWrapper(Screen.PartnershipPortal),
  },
  {
    path: "/TEF",
    component: withWrapper(Screen.TEFCampaign),
  },
  {
    path: "/surgery",
    component: withWrapper(Screen.Surgery),
  },
  {
    path: "/investors-relations",
    component: withWrapper(Screen.Corporate),
  },
  {
    path: "/bancassurance",
    component: withWrapper(Screen.Bancassurance),
  },
  {
    path: "/whistleblowing",
    component: withWrapper(Screen.WhistleBlowing),
  },
  {
    path: "/bancassurance-products",
    component: withWrapper(Screen.BancassuranceProducts),
  },
];

export { routes };
