import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Text = styled("p")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 991px) {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  color: ${Colors.TEXT_DEFAULT};
  margin-bottom: 6px;

  @media screen and (max-width: 991px) {
    font-size: 37px;
    line-height: 37px;
  }

  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 575px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const BannerContent = styled("div")``;
export const BannerContentWrapper = styled("div")`
  margin-top: 5%;

  @media screen and (min-width: 576px) {
    margin-top: 5%;
  }

  @media screen and (min-width: 768px) {
    margin-top: 6%;
  }

  @media screen and (min-width: 991px) {
    margin-top: 4%;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 10%;
  }
`;

export const Overlay = styled("div")`
  margin-top: 4.4%;
  background-color: rgb(243 84 91 / 7%);
`;

export const Wrapper = styled("div")`
  ${containerWrapper}
`;

export const Content = styled("div")`
  min-height: 400px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 991px) {
    min-height: 365px;
  }

  @media screen and (max-width: 767px) {
    min-height: 255px;
  }

  @media screen and (max-width: 575px) {
    min-height: 155px;
  }
`;

export const LinkWrapper = styled("div")`
  margin-top: 15px;
  display: inline-block;
  background: ${Colors.PRIMARY};

  @media screen and (max-width: 767px) {
    margin-top: 5px;
  }

  a {
    padding: 10px 20px;
    color: ${Colors.WHITE};
    display: flex;
    align-items: center;
    font-weight: bold;

    svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }

    &:hover {
      color: ${Colors.WHITE};
      background: ${Colors.BLACK};
    }
  }
`;
