import React, { useState } from "react";
import LeftMenu from "./Navbar/LeftMenu";
import RightMenu from "./Navbar/RightMenu";
import { Drawer } from "antd";
import Promo from "../../../assets/images/promo.gif";
import { Link } from "react-router-dom";
import { Wrapper, Content, MenuContainer, BarsMenu } from "./style";

const Index = ({ onLandingPage, logo }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Wrapper>
      {!onLandingPage && (
        <div className="promo">
          <Link to="/triple-pay">
            <img width="100%" src={Promo} alt="promotions" />
          </Link>
        </div>
      )}
      <Content>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="heirs" />
          </Link>
        </div>
        <MenuContainer>
          {!onLandingPage && (
            <>
              <div className="leftMenu">
                <LeftMenu />
              </div>
            </>
          )}

          <div className="rightMenu">
            <RightMenu onLandingPage={onLandingPage} />
          </div>
          <BarsMenu type="primary" onClick={showDrawer}>
            <span />
          </BarsMenu>
          <Drawer
            title="Menu"
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            {!onLandingPage && <LeftMenu />}
            <RightMenu onLandingPage={onLandingPage} />
          </Drawer>
        </MenuContainer>
      </Content>
    </Wrapper>
  );
};

export default Index;
