import React, { useEffect } from "react";
import { Wrapper, Text, List } from "./styles";
import { Title } from "../PlanPicker/styles";
import { Link } from "react-router-dom";

import hat from "../../assets/images/grad-hat.png";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });

  return (
    <Wrapper id="bike">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={hat} alt="bike" />
                </th>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <Title>Smart School Plan</Title>
                  {/* <Link to="/">Buy Now</Link> */}
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>For Who?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Parents/guardians who have children/wards in Nursery,
                    Primary, Secondary schools & Tertiary Institutions
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Age limit for parents</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>18 - 60 years</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Benefit if there's loss of life or parent suffers permanent
                    disability/critical illness{" "}
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Total outstanding school fees for the duration of the
                    child’s education in that school.
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost of Insurance</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    <p>
                      • For Nursery Schools- The parent pays 1.5% of the child's
                      outstanding school fees until graduation from Nursery
                      school.
                    </p>
                    <p>
                      • For Primary Schools- The parent pays 2% of the child's
                      outstanding school fees until graduation from Primary
                      school.
                    </p>

                    <p>
                      • For Secondary Schools- The parent pays 2.5% of the
                      child's outstanding school fees until graduation from
                      Secondary school.
                    </p>
                    <p>
                      • For Universities- The Parent pays 3% of the child's
                      outstanding school fees until graduation from the
                      University.
                    </p>
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Contribution frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Annually </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Minimum duration</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>1 year; every academic session (renewable yearly)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Medical examination of parent </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    <p>
                      • Not required for payout benefits less than N30 million.
                    </p>
                    <p>
                      • Required if payout benefit is greater than N30 million.
                      (We pay for the medical tests)
                    </p>
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Documents Required</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>Duly Filled Proposal Form.</li>
                    <li>Evidence of Payment.</li>
                    <li>
                      Valid means of ID - Driver license, Voter's card, NIMC,
                      National ID, International Passport.
                    </li>
                    <li>
                      Proof of residence - Nepa or Lawma bill, Front page of the
                      customer's address (same address as indicated on the form
                      or portal), Local government levy.
                    </li>
                    <li>
                      Duly Filled CTR, Financial Questionnaire, Address
                      Verification Form, Bank Statement not more than 6-months
                      (if premium is more than N5 million).
                    </li>
                  </List>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Interested in this Plan?
          {/* <br />
          Plan */}
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
