import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../assets/images/kid-essay.png";
import { Wrapper } from "./styles";
import News from "./News";
import { List } from "../../components/CreditPlanPicker/styles";
import appleStore from "../../assets/images/appstore.png";
import googlePlay from "../../assets/images/playstore.png";
import FacebookIcon from "../../assets/images/heirs-icons-facebook.svg";
import InstagramIcon from "../../assets/images/heirs-icons-instagram.svg";
import YoutubeIcon from "../../assets/images/heirs-icons-youtube.svg";
import LinkedInIcon from "../../assets/images/heirs-icons-linkedin.svg";
import { SocialMediaWrapper } from "../../components/Navigation/Footer/styles";
import { Mixpanel } from "./../../Mixpanel";
import { AutoComplete } from "antd";

const Index = () => {
  const bannerPayload = {
    title: "Heirs Life",
    children: "Essay Championship",
    backgroundImage,
  };
  const mystyle = {
    backgroundColor: "white",
    padding: "45px 30px",
    fontFamily: "Arial",
  };
  const bold = {
    backgroundColor: "red",
    padding: "10px 20px",
    color: "white",
    borderRadius: "10px",
    overflow: "hidden",
  };
  const itlics = {
    fontWeight: "bold",
  };
  const itlicx = {
    fontSize: "13pt",
  };
  const lu = {
    padding: "10px",
  };
  const pad = {
    paddingButtom: "10px",
  };
  const dvstyle = {
    padding: "10px",
    color: "#fff",
    backgroundColor: "rgb(255,0,0)",
    borderRadius: "10px",
    margin: "0 auto",
    textAlign: "center",
    width: "70%",
    fontSize: "13pt",
    fontWeight: "bold",
  };
  const pstyle = {
    textAlign: "center",
    margin: "0 auto",
    fontSize: "13pt",
  };
  const xstyle = {
    margin: "20px auto",
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />

      <div style={xstyle}>
        <p style={dvstyle}>The Heirs Life Essay Championship is now closed</p>
        <p style={pstyle}>Winners will be announced July 19, 2022</p>
        <p style={pstyle}>Follow our social media handles for more updates</p>
        <p style={pstyle}>
          <a href="https://web.facebook.com/heirslifeassurance/">Facebook</a>
          &nbsp; | &nbsp;
          <a href="https://www.instagram.com/heirs.life/">Instagram</a>&nbsp; |
          &nbsp;
          <a href="https://www.linkedin.com/company/heirs-life/">LinkedIn</a>
          &nbsp; | &nbsp;
          <a href="https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ">
            Youtube
          </a>
        </p>
      </div>
      <hr />
      <div style={mystyle}>
        <p>
          The Heirs Life Essay Championship is an annual essay competition open
          to school children across Nigeria between ages 8-13 with the aim of
          showcasing the creativity and talent of children.
        </p>
        <br />
        <p>
          <span style={itlics}>The Entry Process:</span>
          <List>
            <li style={itlicx}>Fill the form below.</li>
            <li style={itlicx}>
              Upload essay. Essay should be 500 words and in font size 12.
            </li>
            <li style={itlicx}>
              Topic:
              <span style={itlics}> If I were the President of Nigeria.</span>
            </li>
            <li style={itlicx}>
              Heirs Life will send a request for confirmation to the
              participant’s school.
            </li>
            <li style={itlicx}>
              Once confirmation is received from the participant’s school, entry
              will be recognized.
            </li>
            <li style={itlicx}>Applications close on June 17, 2022.</li>
            <li style={itlicx}>
              Prizes: The winner will win a brand new laptop and his/her school
              will win upto N500,000 worth of prizes.
            </li>
          </List>
        </p>
        <br />
        <p>Click the link below to register and submit your essay.</p>
        <br />
        <a
          onClick={Mixpanel.track("Essay Championship")}
          href="https://forms.heirsinsurance.com/heirs-life-essay/"
          style={bold}
        >
          Essay Form
        </a>
        <br />
        <br />
        <p>
          ** <span style={itlics}>Note:</span> Entry is only valid after
          confirmation from participant’s school **
        </p>
        <br />
        <p>
          For updates on the Heirs Life Essay Championship follow us on social
          media
        </p>
        <br />
        <SocialMediaWrapper>
          <a
            href="https://www.facebook.com/heirslifeassurance/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={FacebookIcon} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/heirs.life/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={InstagramIcon} alt="instagram" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ"
            target="_blank"
            rel="noreferrer"
          >
            <img src={YoutubeIcon} alt="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/heirs-life/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedInIcon} alt="linkedin" />
          </a>
        </SocialMediaWrapper>
        <br />
        <div style={itlics}>Goodluck!</div>
        <br />
        <div>
          <a href="https://www.heirslifeassurance.com/blog">
            Click here for FAQs
          </a>
        </div>
      </div>
      {/* <News /> */}
    </Wrapper>
  );
};

export default Index;
