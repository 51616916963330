import React, { useEffect } from "react";
import { Text, Title, Wrapper, List } from "../PlanPicker/styles";
import wholeLife from "../../assets/images/whole-life.png";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="annuity">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={wholeLife} alt="annuity" />
                </th>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <Title>Annuity</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Annuity Plan Buy")}
                    href={`${HeirsDashboardUrl}/annuity-option`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Annuity Plan quote")}
                    href={`${HeirsDashboardUrl}/annuity-option`}
                    style={{ margin: "2px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Perfect for?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Retirees</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Age</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>50 and above</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Contribution Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Once</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="2">
                  <Text highlight>Benefits</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Annuity benefit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    A steady amount paid to the annuitant (retiree) for life or
                    until death happens
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Annuity Payment Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Monthly or Quarterly</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support to your beneficiary if death occurs within
                    the first 10 years of the policy.
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>The prorated Annuity balance.</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Documents Required</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>A clear copy of passport photographs (compulsory).</li>
                    <li>
                      One copy of valid means of identification (compulsory).
                    </li>
                    <li>
                      One copy of proof of residence dated in the last six
                      months (compulsory).
                    </li>
                    <li>
                      Retirement letter from the last place of employment
                      (compulsory).
                    </li>
                    <li>
                      Evidence of age (if DOB is not provided on the means of
                      identification) (compulsory).
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      The RSA statement/template (optional, if the premium
                      amount, RSA PEN and PFA name is correctly stated on the
                      proposal form).
                    </li>
                    <i>
                      On the third requirement, the client may use the
                      following:
                    </i>
                    <li>
                      Their utility bill - light, waste disposal, water etc.
                    </li>
                    <li>
                      Tenancy receipt/agreement that bears their residential
                      address.
                    </li>
                    <li>Levy receipt that bears their residential address.</li>
                  </List>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
