import React from "react";
import Section from "../Section";
import { Wrapper, Content, PlanChatWrapper, PlanChatTitle } from "./style";
import PlanItems from "./Items";
import { Link } from "react-router-dom";
import { Mixpanel } from "./../../Mixpanel";

const Plans = () => {
  const handleChatWithUs = (e) => {
    Mixpanel.track("chat with us");
    e.preventDefault();
    window.fcWidget.open({});
  };

  return (
    <Wrapper id="planchoice">
      <Content>
        <Section
          title="Choose Your Plan"
          preTitle="We provide financial support when your loved ones need it"
        >
          <PlanItems />

          <PlanChatWrapper>
            <PlanChatTitle>
              Not Sure of the Right <br />
              Plan for you?
            </PlanChatTitle>
            <Link to="/" onClick={handleChatWithUs}>
              Chat with us
            </Link>
          </PlanChatWrapper>
        </Section>
      </Content>
    </Wrapper>
  );
};

export default Plans;
