import styled from "styled-components";
import * as Colors from "../../utils/colors";

export const PostImage = styled("div")`
  width: 100%;
  height: 25rem;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1.25rem 0;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    background-size: contain;
    height: 18rem;
  }
`;

export const ImageText = styled("span")`
  font-style: italic;
  font-size: 12px;
`;

export const ContentWrapper = styled("div")`
  width: 60%;
  margin: 0 auto;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const BlogHeading = styled("h2")`
  font-size: 20px;
  margin-bottom: 5px;
`;

export const ContentLoader = styled("div")`
  width: 100%;
  height: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PostText = styled("div")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 17px;
  margin-bottom: 15px;

  p {
    margin-bottom: 2rem;
    line-height: 2rem;

    :last-of-type {
      margin-bottom: 12rem;
    }
  }

  div {
    width: 100%;
    height: 25rem;
    margin: 20px 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  iframe {
    width: 100%;
    height: 30rem;
  }
`;
