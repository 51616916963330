import React from "react";
import { Row, Col } from "antd";
import {
  Container,
  Wrapper,
  Title,
  Text,
  Link,
  // DownloadItemContent,
  // DownloadItemWrapper,
  DownloadWrapper,
  SocialMediaWrapper,
  // IconWrapper,
  // SubTitle,
} from "./styles";

// import Icon, { AppleFilled } from "@ant-design/icons";
// import { ReactComponent as PlayIcon } from "../../../assets/images/play.svg";

import appleStore from "../../../assets/images/appstore.png";
import googlePlay from "../../../assets/images/playstore.png";
import FacebookIcon from "../../../assets/images/heirs-icons-facebook.svg";
import InstagramIcon from "../../../assets/images/heirs-icons-instagram.svg";
import TwitterIcon from "../../../assets/images/heirs-icons-twitter.svg";
import LinkedInIcon from "../../../assets/images/heirs-icons-linkedin.svg";

const Index = () => {
  const products = [
    {
      key: "triple",
      path: "/triple-pay",
      name: "Triple Pay Investment",
    },
    {
      key: "life",
      path: "/term-assure",
      name: "Term Life",
    },
    {
      key: "myheirs",
      path: "/my-heirs",
      name: "MyHeirs (Kids Investment)",
    },
    {
      key: "heirssave",
      path: "/heirs-save",
      name: "Heirs Save",
    },
    {
      key: "entrepreneur",
      path: "/entrepreneur",
      name: "Entrepreneurs Investment",
    },
    {
      key: "creditLife",
      path: "/credit-life",
      name: "Credit Life",
    },
    {
      key: "couplePlan",
      path: "/couples",
      name: "Couples Investment",
    },
    {
      key: "wholeLifePlan",
      path: "/whole-life",
      name: "Whole Life Plan",
    },
    {
      key: "salaryPlusPlan",
      path: "/salary",
      name: "Salary Plus Plan",
    },
    {
      key: "mortgagePlan",
      path: "/mortgage",
      name: "Mortgage Protection",
    },
    {
      key: "keymanPlan",
      path: "/keyman-assurance",
      name: "Keyman Assurance",
    },
    // {
    //   key: "annuityPlan",
    //   path: "/annuity",
    //   name: "Life Annuity",
    // },
  ];
  const current_year = new Date().getFullYear();

  return (
    <Container>
      <Wrapper>
        <Row gutter={[150, 0]}>
          <Col lg={10} md={24}>
            <Title>Heirs Life</Title>
            <Text>
              At Heirs Life, we provide financial, family, and future security
              to individuals and businesses. Our wide range of personalized
              investment and pure protection plans assure a dependable safety
              net and emotional shield to clients and their loved ones during
              periods of uncertainty or loss.
            </Text>

            <Col lg={24} md={0} sm={0} xs={0} style={{ marginTop: "40px" }}>
              <Link to={"/privacy"}>PRIVACY POLICY</Link>
              &nbsp;&nbsp;||&nbsp;&nbsp;
              <Link to={"/terms"}>TERMS & CONDITIONS</Link>
              &nbsp;&nbsp;||&nbsp;&nbsp;
              <Link to={"/communication"}>COMMUNICATION POLICY</Link>
              <Text>
                <a href="/whistleblowing">Whistle Blowing.</a>: Call 08113815555{" "}
              </Text>
              <Text>&copy; Copyright {current_year} Heirs Life</Text>
            </Col>
          </Col>
          <Col lg={6} md={10}>
            <Title>Quick links</Title>
            <ul>
              {products.map((product) => (
                <li key={product.key}>
                  <a href={product.path} key={product.key}>
                    {product.name}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={8} md={14}>
            <Title>Follow us</Title>
            <SocialMediaWrapper>
              <a
                href="https://www.facebook.com/heirslifeassurance/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FacebookIcon} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/heirs.life/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstagramIcon} alt="instagram" />
              </a>
              <a
                href="https://twitter.com/HeirsLife_"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TwitterIcon} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/heirs-life/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedInIcon} alt="linkedin" />
              </a>
            </SocialMediaWrapper>

            <DownloadWrapper>
              <Title>Coming Soon</Title>

              {/* <DownloadItemWrapper>
                <Link to="/">
                  <DownloadItemContent>
                    <AppleFilled />
                  </DownloadItemContent>
                  <DownloadItemContent>
                    <SubTitle>Available on the</SubTitle>
                    <Title>App Store</Title>
                  </DownloadItemContent>
                </Link>
              </DownloadItemWrapper>

              <DownloadItemWrapper>
                <Link to="/">
                  <DownloadItemContent>
                    <IconWrapper>
                      <Icon component={PlayIcon} />
                    </IconWrapper>
                  </DownloadItemContent>
                  <DownloadItemContent>
                    <SubTitle>Get it on</SubTitle>
                    <Title>Google Play</Title>
                  </DownloadItemContent>
                </Link>
              </DownloadItemWrapper> */}
              <img src={appleStore} alt={"apple"} />
              <img src={googlePlay} alt={"google"} />
            </DownloadWrapper>
          </Col>
        </Row>

        <Row>
          <Col lg={0}>
            <div style={{ marginTop: "10px" }}>
              <Link to={"/privacy"}>PRIVACY POLICY</Link>
              &nbsp;&nbsp;||&nbsp;&nbsp;
              <Link to={"/terms"}>TERMS & CONDITIONS</Link>
              <Text>Whistle Blowing: Call 08113815555 </Text>
              <Text>&copy; Copyright {current_year} Heirs Life</Text>
            </div>
          </Col>
        </Row>
      </Wrapper>
      <Wrapper style={{ background: "#d6d6d6", width: "100%" }}>
        <Row gutter={[150, 0]}>
          <Col style={{ margin: "0 auto" }}>
            <Text
              style={{
                fontWeight: "bold",
                color: "#000",
                fontSize: "10pt",
              }}
            >
              Authorized and Regulated by the National Insurance Commission, RIC
              NO. 094{" "}
            </Text>
          </Col>
        </Row>
      </Wrapper>
    </Container>
  );
};

export default Index;
