import React, { useEffect } from "react";
import { Text, Title, Wrapper } from "../PlanPicker/styles";
import hat from "../../assets/images/yermassure.png";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
import { Link } from "react-router-dom";
import { List } from "./styles";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "./../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  const fontz = { fontSize: "12pt" };

  return (
    <Wrapper id="credit">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={hat} alt="credit life" />
                </th>
                <th className="ant-table-cell">
                  <Title>Endowment Plan</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Credit Plan Buy")}
                    href={`${HeirsDashboardUrl}/savings-type?policy-type=Heirs%20Endowment%20Assurance`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Credit Plan quote")}
                    href={`${HeirsDashboardUrl}/savings-type?policy-type=Heirs%20Endowment%20Assurance`}
                    style={{ margin: "2px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text style={fontz}>For Who</Text>
                </td>
                <td className="ant-table-cell">
                  <Text style={fontz}>Anyone</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text style={fontz}>Age</Text>
                </td>
                <td className="ant-table-cell">
                  <Text style={fontz}>18 - 65 years</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text style={fontz}>Savings contribution</Text>
                </td>
                <td className="ant-table-cell">
                  <Text style={fontz}>Minimum of N5,000 monthly</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text style={fontz}>Contribution Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text style={fontz}>
                    Single, Monthly, quarterly, half-yearly, yearly
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text style={fontz}>Benefits</Text>
                </td>
                <td className="ant-table-cell">
                  <List style={fontz}>
                    <li>
                      If there's loss of life, your beneficiary gets: Total
                      target amount plus bonus.
                    </li>
                    <li>
                      If you survive the plan, you get: Total target amount plus
                      bonus.{" "}
                    </li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text style={fontz}>
                    Financial support to your beneficiary if there's loss of
                    life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text style={fontz}>Target amount to be chosen by you</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text style={fontz}>Forfeit Terms</Text>
                </td>
                <td className="ant-table-cell">
                  <List style={fontz}>
                    <li>
                      If you terminate the plan within the first 2 years, you
                      agree to forfeit all that you have saved/contributed so
                      far, including the sum assured.
                    </li>
                    <li>
                      After the first 2 years, you can only get a percentage of
                      your savings/contributions.
                    </li>
                  </List>
                </td>
              </tr>
              <tr>
                <td>
                  <Text style={fontz}>Minimum Duration</Text>
                </td>
                <td>
                  <Text style={fontz}>5 years</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Documents Required</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>Duly Filled Proposal Form.</li>
                    <li>Evidence of Payment.</li>
                    <li>
                      Valid means of ID - Driver license, Voter's card, NIMC,
                      National ID, International Passport.
                    </li>
                    <li>
                      Proof of Residence - Nepa or Lawma bill, Front page of the
                      customer's address (same address as indicated on the form
                      or portal), Local government levy.
                    </li>
                    <li>
                      Duly Filled CTR, Financial Questionnaire, Address
                      Verification Form; Bank Statement not more than 6-months
                      (if Premium is more than N5 million).
                    </li>
                    <li>Evidence of Loan Agreement.</li>
                  </List>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
