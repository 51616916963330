import React from "react";
import { Menu, Grid } from "antd";
import Icon, { CaretDownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
// import { ReactComponent as TargetIcon } from "../../../../assets/images/target.svg";
import { ReactComponent as PaymentIcon } from "../../../../assets/images/payment-method.svg";
// import { ReactComponent as ABCBlockIcon } from "../../../../assets/images/abc-block.svg";
import { ReactComponent as ParentsIcon } from "../../../../assets/images/parents.svg";
import { ReactComponent as SalaryPlusIcon } from "../../../../assets/images/salary-plus.svg";
import { ReactComponent as TermLifeIcon } from "../../../../assets/images/term-life.svg";
import { ReactComponent as WholeLifeIcon } from "../../../../assets/images/whole-life.svg";
import { ReactComponent as EntrepreneurIcon } from "../../../../assets/images/entrepreneur.svg";
import { ReactComponent as MyHeirsIcon } from "../../../../assets/images/my-heirs.svg";
import { ReactComponent as HeirsSaveIcon } from "../../../../assets/images/Heirs_save.svg";
import { ReactComponent as CreditLifeIcon } from "../../../../assets/images/credit_life.svg";
import { ReactComponent as GroupLifeIcon } from "../../../../assets/images/group-life.svg";
import { ReactComponent as BlogIcon } from "../../../../assets/images/blog.svg";
import { ReactComponent as NewsIcon } from "../../../../assets/images/news.svg";
import { ReactComponent as MortgageIcon } from "../../../../assets/images/mortgage.svg";
import { ReactComponent as KeymanIcon } from "../../../../assets/images/keyman.svg";
import { ReactComponent as AnnuityIcon } from "../../../../assets/images/annuities.svg";
import { ReactComponent as JoinOurTeamIcon } from "../../../../assets/images/blog.svg";
import { ReactComponent as WhoWeAreIcon } from "../../../../assets/images/news.svg";
import { ReactComponent as MobileIcon } from "../../../../assets/images/mobile.svg";
import { ReactComponent as FinanceIcon } from "../../../../assets/images/finance.svg";
import { ReactComponent as CorporateIcon } from "../../../../assets/images/corporate.svg";
import { ReactComponent as BannerIcon } from "../../../../assets/images/banner.svg";
import { ReactComponent as SurgeryIcon } from "../../../../assets/images/surgery.svg";
import { ReactComponent as SchoolPlanIcon } from "../../../../assets/images/school-plan.svg";
import { ReactComponent as LoanIcon } from "../../../../assets/images/loan.svg";
// import { ReactComponent as LoanProtectIcon } from "../../../../assets/images/loan-protect.svg";
import { IconWrapper } from "../style";

const SubMenu = Menu.SubMenu;

const { useBreakpoint } = Grid;

const LeftMenu = () => {
  const subMenus = [
    // {
    //   key: "TargetSavings",
    //   name: "Target Savings",
    //   icon: TargetIcon,
    //   path: "/product",
    // },
    {
      key: "TriplePay",
      name: "Triple Pay Investment Plan",
      icon: PaymentIcon,
      path: "/triple-pay",
    },
    // {
    //   key: "Children",
    //   name: "Children Education Plus",
    //   icon: ABCBlockIcon,
    //   path: "/product",
    // },
    {
      key: "CouplesInvestment",
      name: "Couples Investment",
      icon: ParentsIcon,
      path: "/couples",
    },
    {
      key: "SalaryPlus",
      name: "Salary Plus",
      icon: SalaryPlusIcon,
      path: "/salary",
    },
    {
      key: "TermLife",
      name: "Term Life",
      icon: TermLifeIcon,
      path: "/term-assure",
    },
    {
      key: "MyHeirs",
      name: "MyHeirs (Kids Investment)",
      icon: MyHeirsIcon,
      path: "/my-heirs",
    },
    {
      key: "GroupLife",
      name: "Group Life",
      icon: GroupLifeIcon,
      path: "/group-life",
    },
    {
      key: "WholeLife",
      name: "Whole Life",
      icon: WholeLifeIcon,
      path: "/whole-life",
    },
    {
      key: "EntrepreneurPlan",
      name: "Entrepreneurs Investment",
      icon: EntrepreneurIcon,
      path: "/entrepreneur",
    },
    {
      key: "HeirsSave",
      name: "Heirs Save Plan",
      icon: HeirsSaveIcon,
      path: "/heirs-save",
    },
    {
      key: "CreditLife",
      name: "Credit Life",
      icon: CreditLifeIcon,
      path: "/credit-life",
    },
    {
      key: "Mortgage",
      name: "Mortgage Protection",
      icon: MortgageIcon,
      path: "/mortgage",
    },
    {
      key: "Keyman",
      name: "Keyman Assurance",
      icon: KeymanIcon,
      path: "/keyman-assurance",
    },
    {
      key: "Annuity",
      name: "Heirs Annuity Pension",
      icon: AnnuityIcon,
      path: "/annuity",
    },
    {
      key: "Surgery",
      name: "Surgery Care",
      icon: SurgeryIcon,
      path: "/surgery",
    },
    {
      key: "SchoolPlans",
      name: "Smart School Plan",
      icon: SchoolPlanIcon,
      path: "/smart-school",
    },
    {
      key: "EndowmentPlan",
      name: "Heirs Endowment Plan",
      icon: LoanIcon,
      path: "/endowment",
    },
  ];

  const konts = [
    {
      key: "contact",
      path: "/contact",
      name: "Contact Us",
      icon: NewsIcon,
    },
    {
      key: "bancassurance",
      path: "/bancassurance",
      name: "Bancassurance",
      icon: EntrepreneurIcon,
    },
  ];

  const subs = [
    {
      key: "news",
      name: "News",
      icon: NewsIcon,
      path: "/news",
    },
    {
      key: "essay",
      name: "School Essay",
      icon: NewsIcon,
      path: "/essay",
    },
    {
      key: "blog",
      name: "Blog",
      icon: BlogIcon,
      path: "/blog",
    },
    // {
    //   key: "financial-statements",
    //   name: "Financial statements",
    //   icon: FinanceIcon,
    //   path: "/financial-statements",
    // },
    {
      key: "corporate-governance",
      name: "Corporate Governance",
      icon: CorporateIcon,
      path: "/corporate-governance",
    },
    // {
    //   key: "product-flyers",
    //   name: "Product Flyers",
    //   icon: BannerIcon,
    //   path: "/product-flyers",
    // },
  ];

  const aboutSubs = [
    {
      key: "who",
      name: "Who we are",
      icon: WhoWeAreIcon,
      path: "/who-we-are",
    },
    {
      key: "digital-channels",
      name: "Digital Channels",
      icon: MobileIcon,
      path: "/digital-channels",
    },
    {
      key: "careers",
      name: "Careers",
      icon: JoinOurTeamIcon,
      path: "/career",
    },
  ];

  const menus = [
    {
      key: "home",
      path: "/home",
      name: "Home",
    },
    {
      key: "products",
      path: "/product",
      name: "Products",
      subMenus,
    },
    // {
    //   key: "about",
    //   path: "/about",
    //   name: "About Us",
    // },
    // {
    //   key: "answers",
    //   path: "/answers",
    //   name: "Get Answers",
    // },
    {
      key: "about",
      path: "/about",
      name: "About Us",
      subMenus: aboutSubs,
    },
    {
      key: "getAnswers",
      path: "/media",
      name: "Media",
      subMenus: subs,
    },
    {
      key: "contact",
      path: "/contact",
      name: "Contact Us",
      // subMenus: konts,
    },
    {
      key: "partnership",
      path: "/partnership",
      name: "Partnerships",
      subMenus: [
        {
          key: "portal",
          name: "Partnership Portal",
          icon: BlogIcon,
          path: "/partnership-portal",
        },
        {
          key: "bancassurance",
          path: "/bancassurance",
          name: "Bancassurance",
          icon: EntrepreneurIcon,
        },
        /* {
          key: "tef",
          name: "Tony Elumelu Foundation",
          icon: BlogIcon,
          path: "/TEF",
        }, */
      ],
    },
  ];

  const { lg } = useBreakpoint();

  const renderSubMenuTitle = (name) => {
    return (
      <span>
        {name} <CaretDownOutlined />
      </span>
    );
  };

  return (
    <Menu mode={lg ? "horizontal" : "inline"}>
      {menus.map((menu) =>
        menu.subMenus ? (
          <SubMenu
            key={menu.key}
            title={renderSubMenuTitle(menu.name)}
            id={menu.key}
          >
            {menu.subMenus.map((subMenu) => (
              <Menu.Item
                key={subMenu.key}
                className={`ant-col ${
                  menu.subMenus.length >= 12 ? "ant-col-lg-8" : "ant-col-lg-12"
                }`}
              >
                <Link to={subMenu.path}>
                  <IconWrapper>
                    <Icon
                      component={subMenu.icon}
                      style={{ fontSize: "20px" }}
                    />
                  </IconWrapper>
                  {subMenu.name}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            <Link to={menu.path}>{menu.name}</Link>
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

export default LeftMenu;
