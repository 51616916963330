import { InboxOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Input, Row, Select, Spin } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import {
  DatePickerWrapper,
  InputWrapper,
  SelectWrapper,
} from "../../components/Input/style";
import { ClaimFormWrapper, FormContentWrapper, Text } from "../Contact/styles";
import SubmitButton from "../../components/Button";
import { Title } from "../Contact/bannerStyle";
import useClaims from "../../hooks/useClaims";
import ClaimsSuccessForm from "./FormRating";

const ClaimsForm = () => {
  const {
    disabledDate,
    error,
    handleAccountNamesChange,
    handleAccountNumberChange,
    handleBeneficiaryFileUpload,
    handleCheckedChange,
    handleClaimsFormSubmit,
    handleClaimSelectChange,
    handleDateChange,
    handleDeathFileUpload,
    handleDeathSelectChange,
    handleDescriptionChange,
    handleEmailChange,
    handleInsuredNameChange,
    handleLocationChange,
    handleMedicalBillFileUpload,
    handleMedicalFileUpload,
    handleMedicalReportFileUpload,
    handleNumberChange,
    handlePhoneNumberChange,
    handlePoliceFileUpload,
    handlePolicyNumberChange,
    handleUploadChange,
    isDisabled,
    isLoading,
    isSuccessful,
    onBeneficiaryIDUpload,
    onDeathCertificateUpload,
    onMedicalBillUpload,
    onMedicalCertificateUpload,
    onMedicalReportUpload,
    onPoliceReportUpload,
    value,
  } = useClaims();
  const { Option } = Select;

  return (
    <>
      {isSuccessful === false ? (
        <ClaimFormWrapper>
          <FormContentWrapper>
            <Title style={{ marginBottom: "30px" }}>
              Heirs Life Fast Claims Portal
            </Title>
            <Text style={{ marginBottom: "30px" }}>
              (Please note that you would be required to upload a few supporting
              documents to make your claim faster)
            </Text>
            <Form layout="vertical" onFinish={handleClaimsFormSubmit}>
              <Row gutter={[16, 0]}>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item>
                    <Form.Item
                      name="insuredName"
                      label="Name of Insured"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please enter the name of the insured!",
                        },
                      ]}
                    >
                      <Row gutter={[4, 16]}>
                        <Col
                          sm={24}
                          md={24}
                          lg={12}
                          xl={12}
                          style={{ width: "100%" }}
                        >
                          <InputWrapper
                            name="insuredFirstName"
                            placeholder="Enter first name"
                            value={value.insuredFirstName}
                            onChange={handleInsuredNameChange}
                          />
                        </Col>
                        <Col
                          sm={24}
                          md={24}
                          lg={12}
                          xl={12}
                          style={{ width: "100%" }}
                        >
                          <InputWrapper
                            name="insuredLastName"
                            placeholder="Enter surname"
                            value={value.insuredLastName}
                            onChange={handleInsuredNameChange}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <small>{error.insuredName}</small>
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item>
                    <Form.Item
                      label="Policy Number"
                      name="policyNumber"
                      rules={[
                        {
                          required: true,
                          messge: "please enter policy number",
                        },
                      ]}
                      hasFeedback
                    >
                      <InputWrapper
                        name="policyNumber"
                        placeholder="Enter policy number"
                        value={value.policyNumber}
                        onChange={handlePolicyNumberChange}
                      />
                    </Form.Item>
                    <small>{error.policyNumber}</small>
                    <Text>(Chat with us if you can't find this)</Text>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item>
                    <Form.Item
                      name="phoneNumber"
                      label="Phone Number"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please enter the phone number",
                        },
                      ]}
                    >
                      <InputWrapper
                        name="phoneNumber"
                        placeholder="Enter phone number"
                        value={value.phoneNumber}
                        onChange={(e) =>
                          handleNumberChange(e, handlePhoneNumberChange)
                        }
                      />
                    </Form.Item>
                    <small>{error.phoneNumber}</small>
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item
                    label="Email Address"
                    name="emailAddress"
                    hasFeedback
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please enter the email address",
                      },
                    ]}
                  >
                    <InputWrapper
                      name="emailAddress"
                      placeholder="Enter email address"
                      value={value.emailAddress}
                      onChange={handleEmailChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Home/Location Address"
                name="homeAddress"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter the home/location address",
                  },
                ]}
              >
                <Input.TextArea
                  name="homeAddress"
                  placeholder="Enter home/location address"
                  value={value.homeAddress}
                  onChange={handleLocationChange}
                />
              </Form.Item>
              <Row gutter={[16, 0]}>
                <Col
                  sm={24}
                  md={value.claimType === "death" ? 12 : 24}
                  lg={value.claimType === "death" ? 12 : 24}
                  xl={value.claimType === "death" ? 12 : 24}
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    name="claimType"
                    label="Type of Claim"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please select a claim!",
                      },
                    ]}
                  >
                    <SelectWrapper
                      name="claimType"
                      placeholder="Please select a claim"
                      value={value.claimType}
                      onChange={handleClaimSelectChange}
                    >
                      <Option value="maturity">Maturity</Option>
                      <Option value="death">Death</Option>
                      <Option value="disablement">Disablement</Option>
                      <Option value="critical illness">Critical illness</Option>
                      {/* <Option value="loss of job">Loss of Job</Option> */}
                      <Option value="loan">Loan</Option>
                    </SelectWrapper>
                  </Form.Item>
                </Col>
                {value.claimType === "death" ? (
                  <Col sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="deathType"
                      label="Cause of Death"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please select a cause!",
                        },
                      ]}
                    >
                      <SelectWrapper
                        name="deathType"
                        placeholder="Please select a cause"
                        value={value.deathType}
                        onChange={handleDeathSelectChange}
                      >
                        <Option value="accident">Accident</Option>
                        <Option value="natural">Natural Death</Option>
                      </SelectWrapper>
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
              <Form.Item>
                <Text>
                  For Surrender/Part-withdrawal claims, send a message to{" "}
                  <a href="mailto:wecare@heirslifeassurance.com">
                    wecare@heirslifeassurance.com
                  </a>
                </Text>
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="description"
                  label="Brief description of Incident (not more than 100 characters)"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please enter brief description of incident",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Enter description"
                    name="description"
                    value={value.description}
                    onChange={handleDescriptionChange}
                    maxLength={100}
                  />
                </Form.Item>
                {value.description.length > 0 ? (
                  <small>{value.description.length}/100</small>
                ) : null}
              </Form.Item>
              {value.claimType === "maturity" ? (
                <Form.Item>
                  <Form.Item
                    name="accountDetails"
                    label="Account Details"
                    rules={[{ required: true }]}
                  >
                    <Row gutter={[16, 16]}>
                      <Col sm={24} md={12} lg={8} xl={8}>
                        <InputWrapper
                          name="accountName"
                          placeholder="Account name"
                          value={value.accountName}
                          onChange={handleAccountNamesChange}
                        />
                        <small>{error.accountName}</small>
                      </Col>
                      <Col sm={24} md={12} lg={8} xl={8}>
                        <InputWrapper
                          name="bankName"
                          placeholder="Name of Bank"
                          value={value.bankName}
                          onChange={handleAccountNamesChange}
                        />
                        <small>{error.bankName}</small>
                      </Col>
                      <Col sm={24} md={12} lg={8} xl={8}>
                        <InputWrapper
                          name="accountNumber"
                          placeholder="Account number"
                          value={value.accountNumber}
                          onChange={(e) =>
                            handleNumberChange(e, handleAccountNumberChange)
                          }
                        />
                        <small>{error.accountNumber}</small>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              ) : null}
              {value.claimType === "death" ? (
                <>
                  <Row gutter={[16, 0]}>
                    <Col
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ width: "100%" }}
                    >
                      <Form.Item
                        name="incidentDate"
                        label="Date of Incident"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please enter date of incident",
                          },
                        ]}
                      >
                        <DatePickerWrapper
                          disabledDate={disabledDate}
                          onChange={handleDateChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Form.Item
                      name="deathCertificateUpload"
                      label="Upload Death Certificate (NPC)"
                      valuePropName="fileList"
                      getValueFromEvent={onDeathCertificateUpload}
                    >
                      <Dragger
                        name="logo"
                        onChange={handleUploadChange}
                        fileList={value.deathCertificate}
                        listType="picture"
                        customRequest={handleDeathFileUpload}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">Max file size: 1 MB</p>
                      </Dragger>
                    </Form.Item>
                    <small>{error.deathCertificate}</small>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="medicalCertificate"
                      label="Upload Medical certificate of cause of death"
                      valuePropName="fileList"
                      getValueFromEvent={onMedicalCertificateUpload}
                    >
                      <Dragger
                        name="logo"
                        onChange={handleUploadChange}
                        fileList={value.medicalCertificate}
                        listType="picture"
                        customRequest={handleMedicalFileUpload}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">Max file size: 1 MB</p>
                      </Dragger>
                    </Form.Item>
                    <small>{error.medicalCertificate}</small>
                  </Form.Item>
                  {value.deathType !== "accident" ? (
                    <Form.Item>
                      <Form.Item
                        name="policeReport"
                        label="Upload a Police report"
                        valuePropName="fileList"
                        getValueFromEvent={onPoliceReportUpload}
                      >
                        <Dragger
                          name="logo"
                          onChange={handleUploadChange}
                          fileList={value.policeReport}
                          listType="picture"
                          customRequest={handlePoliceFileUpload}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">Max file size: 1 MB</p>
                        </Dragger>
                      </Form.Item>
                      <small>{error.policeReport}</small>
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <Form.Item
                        name="policeReport"
                        label="Upload a Police report"
                        valuePropName="fileList"
                        getValueFromEvent={onPoliceReportUpload}
                        rules={[
                          {
                            required: true,
                            message: "please upload police report",
                          },
                        ]}
                      >
                        <Dragger
                          name="logo"
                          onChange={handleUploadChange}
                          fileList={value.policeReport}
                          listType="picture"
                          customRequest={handlePoliceFileUpload}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">Max file size: 1 MB</p>
                        </Dragger>
                      </Form.Item>
                      <small>{error.policeReport}</small>
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Form.Item
                      name="beneficiaryID"
                      label="Upload I.D card of the beneficiary"
                      valuePropName="fileList"
                      getValueFromEvent={onBeneficiaryIDUpload}
                    >
                      <Dragger
                        name="logo"
                        onChange={handleUploadChange}
                        fileList={value.beneficiaryID}
                        listType="picture"
                        customRequest={handleBeneficiaryFileUpload}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">Max file size: 2 MB</p>
                      </Dragger>
                    </Form.Item>
                    <small>{error.beneficiaryID}</small>
                  </Form.Item>
                </>
              ) : null}

              {value.claimType &&
              (value.claimType === "critical illness" ||
                value.claimType === "disablement") ? (
                <Row gutter={[16, 16]}>
                  <Col span={24} lg={12} xl={12} id="medicalReportUpload">
                    <Form.Item>
                      <Form.Item
                        name="medicalReportUpload"
                        label="Upload Medical Report"
                        valuePropName="fileList"
                        getValueFromEvent={onMedicalReportUpload}
                        extra="Attach File (Max size: 1 MB)"
                      >
                        <Dragger
                          name="logo"
                          onChange={handleUploadChange}
                          fileList={value.medicalReport}
                          listType="picture"
                          customRequest={handleMedicalReportFileUpload}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">Max file size: 1 MB</p>
                        </Dragger>
                      </Form.Item>
                      <small>{error.medicalReport}</small>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12} xl={12} id="medicalBillUpload">
                    <Form.Item>
                      <Form.Item
                        name="medicalBillUpload"
                        label="Upload Medical Bills"
                        valuePropName="fileList"
                        getValueFromEvent={onMedicalBillUpload}
                        extra="Attach File (Max size: 1 MB)"
                      >
                        <Dragger
                          name="logo"
                          onChange={handleUploadChange}
                          fileList={value.medicalBill}
                          listType="picture"
                          customRequest={handleMedicalBillFileUpload}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">Max file size: 1 MB</p>
                        </Dragger>
                      </Form.Item>
                      <small>{error.medicalBill}</small>
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}

              {value.claimType &&
              value.claimType === "surrender/part-withdrawal" ? (
                <>
                  <Form.Item>
                    <Form.Item name="accountDetails" label="Account Details">
                      <Row gutter={[16, 16]}>
                        <Col sm={24} md={12} lg={8} xl={8}>
                          <InputWrapper
                            name="accountName"
                            placeholder="Account name"
                            value={value.accountName}
                            onChange={handleAccountNamesChange}
                          />
                          <small>{error.accountName}</small>
                        </Col>
                        <Col sm={24} md={12} lg={8} xl={8}>
                          <InputWrapper
                            name="bankName"
                            placeholder="Name of Bank"
                            value={value.bankName}
                            onChange={handleAccountNamesChange}
                          />
                          <small>{error.bankName}</small>
                        </Col>
                        <Col sm={24} md={12} lg={8} xl={8}>
                          <InputWrapper
                            placeholder="Account number"
                            value={value.accountNumber}
                            onChange={(e) =>
                              handleNumberChange(e, handleAccountNumberChange)
                            }
                          />
                          <small>{error.accountNumber}</small>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form.Item>
                </>
              ) : null}
              <>
                {value.claimType !== "surrender/part-withdrawal" &&
                value.claimType !== "maturity" &&
                value.claimType !== "loan" &&
                value.claimType !== "loss of job" ? (
                  <Form.Item name="trueClaims">
                    <Checkbox
                      checked={value.trueClaims}
                      onChange={handleCheckedChange}
                    >
                      Confirm that this claim is valid. If claim is found to be
                      false or partly fraudulent, you will forfeit the entire
                      monetary value of your claim and be liable to legal
                      action.
                    </Checkbox>
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <Form.Item>
                    {isLoading ? (
                      <SubmitButton disabled style={{ width: "100%" }}>
                        <Spin size="small" />
                      </SubmitButton>
                    ) : (
                      <SubmitButton
                        disabled={isDisabled}
                        style={{ width: "100%" }}
                        htmlType="submit"
                      >
                        Submit
                      </SubmitButton>
                    )}
                  </Form.Item>
                  <small>{error.submitError}</small>
                </Form.Item>
              </>
            </Form>
          </FormContentWrapper>
        </ClaimFormWrapper>
      ) : (
        <ClaimsSuccessForm
          claimType={value.claimType}
          insuredName={`${value.insuredFirstName} ${value.insuredLastName}`}
        />
      )}
    </>
  );
};

export default ClaimsForm;
